import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				PrimeFit Arena
			</title>
			<meta name={"description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:title"} content={"PrimeFit Arena"} />
			<meta property={"og:description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12:13:02.771Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12:13:02.780Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-2.jpg?v=2024-02-14T12%3A13%3A02.780Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12:13:02.759Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12%3A13%3A02.759Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12%3A13%3A02.759Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12%3A13%3A02.759Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12%3A13%3A02.759Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12%3A13%3A02.759Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12%3A13%3A02.759Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-3.jpg?v=2024-02-14T12%3A13%3A02.759Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12:13:02.751Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Key Services
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					PrimeFit Arena offers a holistic fitness experience that addresses all aspects of your wellness journey. From innovative workout classes to personalized training, our services are designed to inspire, challenge, and transform.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Dynamic Workout Classes
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						HIIT Sessions
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Engage in high-intensity interval training that boosts cardiovascular fitness and accelerates fat loss.
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Yoga and Mindfulness
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Relax and strengthen your mind-body connection with our yoga sessions suitable for all levels.
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Strength Training
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Build muscle and increase endurance with our targeted strength training programs using the latest equipment.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12:13:02.768Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/4-3.jpg?v=2024-02-14T12%3A13%3A02.768Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro\n\n&quot;, sans-serif"
				>
					Personal Training
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					- Personalized Fitness Plans: Work one-on-one with our certified trainers to create a fitness plan tailored to your goals, preferences, and lifestyle.
					<br />
					- Nutritional Guidance: Get expert nutritional advice to complement your fitness regime, optimizing overall health and performance.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contact
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Wellness and Recovery
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					- Spa and Sauna: Rejuvenate and relax after your workout with our spa and sauna facilities that promote recovery and well-being.
					<br />
					- Massage Therapy: Choose from a variety of massage treatments offered by our qualified therapists to relieve muscle tension and enhance recovery.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contact
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1611073615452-4889cb93422e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12:13:02.762Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-3.jpg?v=2024-02-14T12%3A13%3A02.762Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					State-of-the-Art Equipment
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					- Modern Equipment: Explore a wide range of the latest machines catering to both cardiovascular and strength training needs.
					<br />
					- Spacious Workout Areas: Enjoy your workouts in our spacious, well-designed areas that promote motivation and focus.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contact
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc500072400020e9c714"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});